import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import web3Bull from "../../images/tronpedia/topic-pages/web3-bull.png";

const Web3 = ({data, location}) => {

    const pageData = {
        title: "Web3",
        description: "Welcome to the future. These articles cover how we have and potentially will be acting with the internet. They also talk about some of the biggest names in the game trying to move it forward.",
        img: web3Bull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Web3 | TRONDAO</title>
                <meta name="description"
                      content="TRONPedia is your one stop for information on web3. From how it works to how it will change the world as we know it. So, what are you waiting for?"/>
                <meta property="og:description"
                      content="TRONPedia is your one stop for information on web3. From how it works to how it will change the world as we know it. So, what are you waiting for?"/>
                <meta property="og:title" content="TRONpedia Web3 | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Web3;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "WEB3"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
